import React, { useEffect, useState } from 'react';
import { useParams } from "react-router";
import { SalonService } from '../../services/SalonService';
import "./companyManagement.css";
import Header from "../../components/header/Header";
import ArrowLeft from "../../components/arrow-left/ArrowLeft";
import HorizontalLine from '../../components/hr-line/HorizontalLine';

export default function CompanyManagement() {

    //Obter dados salão - usuário - agendamento
    const param = useParams();
    const salonId = param.salonId;

    const [AgendamentoData, SetAgendamentoData] = useState(null); // Estado para armazenar dados 
    useEffect(() => {
        const fetchSalonData = async () => {
            const scheduling = await SalonService.obterScheduling(salonId);
            SetAgendamentoData(scheduling);

        }
        fetchSalonData();

    }, [salonId]);

    return (
        <>
            <Header />
            <HorizontalLine />
            <ArrowLeft />
            <div>
                <h2>Gerenciar Reservas</h2>
                <form>
                    {AgendamentoData ? (
                        AgendamentoData.map((agendamento) => (
                            <div className='listComanyManagement'>
                                <p className='dataManagement'></p>
                                <p>{agendamento.UserName}</p>
                                <p className='dataManagement'>Data:</p>
                                <p>{agendamento.date}</p>
                                <p className='dataManagement'>horário:</p>
                                <p>{agendamento.time}</p>
                                <p className='dataManagement'>Telefone:</p>
                                <p>{agendamento.UserPhone}</p>
                                <button className='button3' type='submit'>Cancelar</button>
                            </div>
                        ))
                    ) : (<p>Carregando agendamentos</p>)
                    }
                </form>
            </div>
        </>
    )
}