import React from "react";
import { Button } from "react-bootstrap";
import Image from "../../assets/images/women.png";
import "./Banner.css";
import { useNavigate } from "react-router-dom";

function Banner() {
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/plans')
    }

  return (
    <div className="container banner-container">
      <div className="row align-items-center">
        <div className="col">
          <img
            src={Image}
            alt="Beauty Model"
            className="img-fluid img-banner"
          />
        </div>

        <div className="col container-text text-start">
          <h2 className="banner-title">Cuide de você com o Beauty Pass</h2>
          <p className="text-title mt-4">
            Tenha acesso a serviços de beleza e bem-estar personalizados,
          </p>
          <p className="text-title">elevando sua autoestima e facilitando o autocuidado de forma acessível.</p>
          <Button className="banner-button" onClick={handleNavigate}>Buscar Plano</Button>
        </div>
      </div>
    </div>
  );
}

export default Banner;
