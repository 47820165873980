import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import "./Footer.css";

export default function Footer() {
  return (
    <>
    <div className="footer-container">
    <div className="text-container">
        <p className="text-footer">2024 BeautyPass. Todos os direitos reservados.</p>
        <p>Política de privacidade</p>
        <p>Termos de uso</p>
      </div>

      <div className="social-icons">
            <a href="https://facebook.com/yourprofile" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a href="https://instagram.com/yourprofile" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="https://twitter.com/yourprofile" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                <FontAwesomeIcon icon={faTwitter} />
            </a>
        </div>

    </div>
    </>
  );
}
