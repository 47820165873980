import React, { useEffect, useState } from 'react';
import "./CompanyArea.css";
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router";
import { SalonService } from '../../services/SalonService';
import { IMaskInput } from "react-imask";
import upload from './images/upload.png';
import Header from "../../components/header/Header";
import ArrowLeft from "../../components/arrow-left/ArrowLeft";
import HorizontalLine from '../../components/hr-line/HorizontalLine';

export default function CompanyArea() {

    const [formData, setFormData] = useState({
        userId: '123',
        companyId: '',
        companyName: '',
        cnpj: '',
        companyPhone: '',
        companyAdress: '',
        companyImageUrl: ''
    });

    //Obter dados do salão
    const param = useParams();
    const id = param.salonId;
    console.log(param.salonId);
    const navigate = useNavigate();
    const [salonData, setSalonData] = useState(null); // Estado para armazenar dados do salão
    useEffect(() => {
        const fetchSalonData = async () => {

            const salon = await SalonService.obterSalon(id);

            setSalonData(salon);
        }
        fetchSalonData();
    }, []);


    //Redirecionamentos
    const HandleNavigateHomePage = () => {
        navigate(`/home-page`); 
    };
    const HandleNavigateManagePlans = () => {
        navigate(`/service-management/${id}`); // Redireciona para gerenciar servicos
    };
    const HandleNavigateServiceRegistration = () => {
        navigate(`/service-registration/${id}`); // Redireciona para Cadastrar Serviços
    };


    const HandleNavigateCompanyManagement = () => {
        navigate(`/company-management/${id}`); // Redireciona para Gerenciar Reservas
    };
    const [companyImage, setCompanyImage] = useState();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    //atualizar dados salão
    const atualizarSalon = async (e) => {
        try {
            e.preventDefault();
            const id = await SalonService.atualizarSalon(formData);

            SalonService.salvarImagem(id, companyImage);
            navigate(`/company-area/${id}`); // Redireciona para Company Area
        } catch (error) {
            console.error("Erro na resposta da requisição:", error);
            alert("Erro", "Falha ao cadastrar salão. Por favor, tente novamente mais tarde.");
        }
    };
    const uploadedImage = (e) => {
        let file = e.target.files[0];
        setCompanyImage(file);

        setFormData({
            ...formData,
            ['companyImageUrl']: formData.companyId
        });
    };

    return (
        <>
        <Header />
        <HorizontalLine />
        <ArrowLeft />
            {salonData ? (
                            <div>
                                <h2 >Olá, {salonData.salon.companyName}!</h2> 
                            </div>
                        ) : ''}
            <div className='companyarea'>
                
                {salonData ? (
                    <div className='formularioCompanyArea'>
                        <form onSubmit={atualizarSalon} method='PUT'>
                            <input className='input' type='hidden' name='companyId' value={formData.companyId}></input>                    
                            <label className='label'>Nome da empresa </label><br />
                            <input className='inputCompanyArea' type='text' name='companyName' value={salonData.salon.companyName} /><br /><br />
                            <label className='label'>Cnpj </label><br />
                            <IMaskInput className='inputCompanyArea' mask="00.000.000/0001-00" type='text' name='cnpj' value={salonData.salon.cnpj} /><br /><br />
                            <label className='label'>Endereço </label><br />
                            <input className='inputCompanyArea' type='text' name='companyAdress' value={salonData.salon.companyAdress} /><br /><br />
                            <label className='label'>Telefone </label><br />
                            <IMaskInput className='inputCompanyArea' mask="(00) 00000-0000" type='text' name='companyPhone' value={salonData.salon.companyPhone} /><br /><br />
                            <div className="imgApp">
                                <div className="parent">
                                    <div className="file-upload">
                                        <img className="img-upload" src={upload} alt="upload" />
                                        <p>Envie a imagem da empresa</p>
                                        <input className='input' type='file' name='companyImage' accept='.png' onChange={uploadedImage} ></input>
                                    </div>
                                </div>
                            </div><br />
                            <button className='button' type='submit'  >Atualizar</button><br />
                        </form>
                    </div>
                ) : ''}
                <div className='CompanyArea1'>
                    <form >
                        <div className='listCompanyArea'>
                            <label className='labelCompanyArea'>Gerenciar Reservas </label>
                            <button className='button1' type='submit' onClick={HandleNavigateCompanyManagement}>Editar</button> {/* Redireciona para Gerenciar Reservas*/}
                        </div>
                        <div className='listCompanyArea'>
                            <label className='labelCompanyArea'>Cadastrar Serviços </label>
                            <button className='button1' type='submit' onClick={HandleNavigateServiceRegistration}>Editar</button> {/* Redireciona para Cadastrar Serviços*/}
                        </div>
                        <div className='listCompanyArea'>
                            <label className='labelCompanyArea' >Gerenciar Serviços</label>
                            <button className='button1' type='submit' onClick={HandleNavigateManagePlans} >Editar</button>  
                        </div>
                        <button className='button' type='submit' onClick={HandleNavigateHomePage} >Sair</button>   {/* Redireciona Home-Page*/}
                    </form>
                </div>
            </div >
        </>
    )
}

