
import React, { useState, } from 'react';
import { useParams } from "react-router-dom";
import "./serviceRegistration.css";
import { SalonService } from '../../services/SalonService';
import Header from "../../components/header/Header";
import ArrowLeft from "../../components/arrow-left/ArrowLeft";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import 'moment-timezone';
import { TimePicker } from 'react-ios-time-picker';
import { useNavigate } from 'react-router-dom';
import HorizontalLine from '../../components/hr-line/HorizontalLine';


export default function ServiceRegistration() {
    const navigate = useNavigate();
    const { salonId } = useParams(); // Recebe salonId da URL
    const [formData, setFormData] = useState({
        serviceId: '',
        salonId: salonId,
        name: '',
        description: '',
        duration: '1',
        createdAt: '',
    });

    const handleCadastroService = async (e) => {
        try {
            e.preventDefault();
            const availableSlots = [];

            const data1 = new Date(dates[1]);
            const data2 = new Date(dates[0]);
            const diff = Math.abs(data1 - data2);
            const dias = Math.ceil(diff / (1000 * 60 * 60 * 24));

            const start = parseInt(hourStart.toString().substring(0, 2));
            const end = parseInt(hourEnd.toString().substring(0, 2));

            for (let i = 0; i < dias; i++) {
                let d = new Date();
                d.setDate(data2.getDate() + i)
                let slot = {
                    day: d.toLocaleDateString(),
                    slots: []
                }

                for (let j = 0; j < (end - start); j++) {
                    let timeSlot = {
                        time: (start + j).toString() + ':00',
                        isAvailable: true
                    }
                    slot.slots.push(timeSlot)
                }

                availableSlots.push(slot);
            }
            const id = await SalonService.cadastrarService(formData, availableSlots);
            navigate(`/service-management/${salonId}`); // Redireciona para Gerenciar Serviços

        } catch (error) {
            console.error("Erro na resposta da requisição:", error);
            alert("Erro", "Falha ao cadastrar serviço. Por favor, tente novamente mais tarde.");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    const [dates, setDates] = useState([]);
    const handleChangeDates = (e) => {
        setDates(e);
    };

    const [hourStart, setHourStart] = useState([]);
    const handleChangeHourStart = (e) => {
        setHourStart(e);
    };

    const [hourEnd, setHourEnd] = useState([]);
    const handleChangeHourEnd = (e) => {
        setHourEnd(e);
    };


    return (
        <>
            <Header />
            <ArrowLeft />
            <h2>Cadastrar Serviços</h2>

            <div className='registerServices'>

                <form onSubmit={handleCadastroService} method='post'>
                    <label className='labelRegisterServices'>Nome do Serviço</label>
                    <input className='inputServiceRegistration' type='text' name='name' onChange={handleChange} value={formData.name} />
                    <label className='labelRegisterServices'>Descrição do Serviço</label>
                    <input className='inputServiceRegistration' type='text' name='description' onChange={handleChange} value={formData.description} />
                    <label className='labelCalendar'>Selecione as datas disponíveis   </label>
                    <Calendar onChange={handleChangeDates} selectRange={true} />
                    <label className='labelCalendar'>Horário de funcionamento:</label>
                    <div className='openingHours'>
                        <p className='hours'>Das</p>
                        <TimePicker onChange={handleChangeHourStart} />
                        <p className='hours1'>às</p>
                        <br></br>
                        <TimePicker onChange={handleChangeHourEnd} />

                    </div>
                    <button className='button5' type='submit' >Cadastrar</button>
                </form>
            </div>



        </>

    )

}