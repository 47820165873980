

export class SalonService {
    //Validação das informações de cadastro do salão
    static async cadastrarSalon(salon) {
        const baseApi_URL = 'https://pmv-ads-2024-2-e4-proj-infra-t6-beauty-qbcl.onrender.com';
        const salonAPI_URL = `${baseApi_URL}/api/salon`;
        const companyName = salon.companyName;
        const email = salon.email;
        const senha = salon.senha;
        const cnpj = salon.cnpj;
        const companyPhone = salon.companyPhone;
        const companyAdress = salon.companyAdress;
        const companyImage = salon.companyImage;
        const companyImageUrl = salon.companyImageUrl;

        try {
            const userData = {
                UserAdress: companyAdress,
                cpf: cnpj,
                UserPhone: companyPhone,
                UserName: companyName,
                UserEmail: email,
                passwordHash: senha
            };

            let userId = '';

            // Enviar os dados para o backend via fetch
            const responseUser = await fetch('https://userservice-h4hn.onrender.com/api/user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData)
            });

            if (responseUser.ok) {
                const data = await responseUser.json();
                userId = data.id;
            } else {
                console.log('Erro ao cadastrar usuário:', responseUser.status);
            }

            const response = await fetch(salonAPI_URL, { //API URL do cadastro salão
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userId,
                    companyName,
                    senha,
                    email,
                    cnpj,
                    companyPhone,
                    companyAdress,
                    companyImageUrl,
                    companyImage
                }),
            });
            console.log("Resposta: " + response.status);

            if (response.ok) {
                const responseData = await response.json();
                return responseData.salonId;
            } else {
                const errorMessage = await response.text();
                throw new Error(`Falha ao cadastrar salão: ${response.status} - ${errorMessage}`);
            }
        } catch (error) {
            console.error("Erro na resposta da requisição:", error);
        }
    }

    //Atualizar as informações de cadastro do salão
    static async atualizarSalon(salon) {
        const baseApi_URL = 'https://pmv-ads-2024-2-e4-proj-infra-t6-beauty-qbcl.onrender.com';
        const salonAPI_URL = `${baseApi_URL}/api/salon/${salon.companyId}`;
        const userId = salon.userId;
        const companyName = salon.companyName;
        const email = salon.email;
        const senha = salon.senha;
        const cnpj = salon.cnpj;
        const companyPhone = salon.companyPhone;
        const companyAdress = salon.companyAdress;
        const companyImage = salon.companyImage;
        const companyImageUrl = salon.companyImageUrl;

        try {
            const response = await fetch(salonAPI_URL, { //API URL do cadastro salão
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    userId,
                    companyName,
                    email,
                    senha,
                    cnpj,
                    companyPhone,
                    companyAdress,
                    companyImageUrl,
                    companyImage
                }),
            });
            console.log("Resposta: " + response.status);
            if (response.ok) {
                const responseData = await response.json();
                return responseData.salonId;
            } else {
                const errorMessage = await response.text();
                throw new Error(`Falha ao cadastrar salão: ${response.status} - ${errorMessage}`);
            }
        } catch (error) {
            console.error("Erro na resposta da requisição:", error);
        }
    }

    static async obterSalon(id) {
        const baseApi_URL = 'https://pmv-ads-2024-2-e4-proj-infra-t6-beauty-qbcl.onrender.com';
        const salonAPI_URL = `${baseApi_URL}/api/salon/${id}`;
        try {
            const response = await fetch(salonAPI_URL, { //API URL do cadastro salão
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.ok) {
                return await response.json();
            } else {
                const errorMessage = await response.text();
                throw new Error(`Falha ao obter salão: ${response.status} - ${errorMessage}`);
            }
        } catch (error) {
            console.error("Erro na resposta da requisição:", error);
        }
    }

    static async salvarImagem(id, file) {
        const baseApi_URL = 'https://pmv-ads-2024-2-e4-proj-infra-t6-beauty-qbcl.onrender.com';
        const salonAPI_URL = `${baseApi_URL}/api/salon-img/${id}`;

        try {
            const formData = new FormData();
            formData.append('file', file);
            const response = await fetch(salonAPI_URL, { //API URL do cadastro salão
                method: "POST",
                body: formData
            });

            if (response.ok) {
                const responseData = await response.json();
            } else {
                const errorMessage = await response.text();
                throw new Error(`Falha ao cadastrar imagem do salão: ${response.status} - ${errorMessage}`);
            }
        } catch (error) {
            console.error("Erro na resposta da requisição:", error);
        }
    };


    // Obter dados do agendamento
    static async obterScheduling(salonId) {
        const baseApiSchedule_URL = 'https://pmv-ads-2024-2-e4-proj-infra-t6-beauty-46ik.onrender.com';  //API URL do cadastro do agendamento
        const baseApiUser_URL = 'https://userservice-h4hn.onrender.com'; //API URL do cadastro do usuário
        const schedulingAPI_URL = `${baseApiSchedule_URL}/api/schedules/salon/${salonId}`;
        const agendamentos = [];

        try {
            const responseAgendamento = await fetch(schedulingAPI_URL, { //API URL do cadastro do agendamento
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (responseAgendamento.ok) {
                const data = await responseAgendamento.json();

                await Promise.all(data.map(async (a) => {
                    const userAPI_URL = `${baseApiUser_URL}/api/user/${a.userId}`;
                    const responseUsuario = await fetch(userAPI_URL, { //API URL do cadastro do usuário
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                        },
                    });

                    const agendamento = {
                        confirmationStatus: '',
                        date: '',
                        time: '',
                        UserName: '',
                        UserPhone: '',
                    };

                    const user = await responseUsuario.json();
                    agendamento.confirmationStatus = a.confirmationStatus;
                    agendamento.date = a.date;
                    agendamento.time = a.time;
                    agendamento.UserName = user.UserName;
                    agendamento.UserPhone = user.UserPhone;

                    agendamentos.push(agendamento);
                }));

            } else {

                throw new Error('Falha ao obter agendamento');
            }
        } catch (error) {
            console.error("Erro na resposta da requisição:", error);
        }


        return agendamentos;
    }



    // Cadastrar dados do Detalhes do Serviço
    static async cadastrarService(service, availableSlots) {
        const baseApiServiceDetails_URL = 'https://servicedetails-vsd2.onrender.com';  //API URL do cadastro dos Detalhes do Serviço
        const serviceDetailsAPI_URL = `${baseApiServiceDetails_URL}/api/services`;
        const serviceId = service.serviceId;
        const salonId = service.salonId;
        const name = service.name;
        const description = service.description;
        const duration = service.duration;
        const createdAt = service.createdAt;

        try {
            const response = await fetch(serviceDetailsAPI_URL, { //API URL do cadastro dos Detalhes do Serviço
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    serviceId,
                    salonId,
                    name,
                    description,
                    duration,
                    availableSlots,
                    createdAt
                }),
            });


            if (response.ok) {
                const responseData = await response.json();
                return responseData.salonId;
            } else {
                const errorMessage = await response.text();
                throw new Error(`Falha ao cadastrar serviço: ${response.status} - ${errorMessage}`);
            }
        } catch (error) {
            console.error("Erro na resposta da requisição:", error);
        }
    }

    // Obter dados dos serviços cadastrados por salão
    static async obterReservation(salonId) {
        const baseApiServiceDetails_URL = 'https://servicedetails-vsd2.onrender.com';  //API URL do cadastro dos Detalhes do Serviço
        const serviceDetailsAPI_URL = `${baseApiServiceDetails_URL}/api/salons/${salonId}/services`;
        const servicos = [];

        try {
            const responseServico = await fetch(serviceDetailsAPI_URL, { //API URL do cadastro dos Detalhes do Serviço
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (responseServico.ok) {
                const data = await responseServico.json();
                await Promise.all(data.services.map(async (a) => {
                    const servico = {
                        name: '',
                        description: '',
                        duration: '',
                    };

                    servico.name = a.name;
                    servico.description = a.description;
                    servico.duration = a.duration;

                    servicos.push(servico);
                }));



            } else {

                throw new Error('Falha ao obter serviços');
            }
        } catch (error) {
            console.error("Erro na resposta da requisição:", error);
        }


        return servicos;
    }

    static async obterSalonByUserId(userId) {
        const baseApi_URL = 'https://pmv-ads-2024-2-e4-proj-infra-t6-beauty-qbcl.onrender.com';
        const salonAPI_URL = `${baseApi_URL}/api/salonByUser/${userId}`;
        try {
            const response = await fetch(salonAPI_URL, { //API URL do cadastro salão
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.ok) {
                const data = await response.json();
                return data.salon;
            } else {
                const errorMessage = await response.text();
                throw new Error(`Falha ao obter salão por usuário: ${response.status} - ${errorMessage}`);
            }
        } catch (error) {
            console.error("Erro na resposta da requisição:", error);
        }
    }

}
