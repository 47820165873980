import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./PricingCard.css";

function PricingCard({ title, priceMain, priceSub, backgroundColor, onSelect, onClick, btnText }) {
  const handleClick = () => {
    if (onClick) {
      onClick(); 
    } else if (onSelect) {
      onSelect(title, priceMain); 
    }
  };

  return (
    <Card className="pricing-card" style={{ backgroundColor }}>
      <Card.Body>
        <Card.Title className="card-title">{title}</Card.Title>
        <Card.Text className="card-text">
          {priceMain}
          <span className="card-price-small">{priceSub}</span>
        </Card.Text>
        <Button className="card-btn" variant="primary" onClick={handleClick}>
          {btnText}
        </Button>
      </Card.Body>
    </Card>
  );
}

export default PricingCard;
