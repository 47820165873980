import React, { useEffect, useState } from 'react';
import { useParams } from "react-router";
import { SalonService } from '../../services/SalonService';
import "./serviceManagement.css";
import Header from "../../components/header/Header";
import ArrowLeft from "../../components/arrow-left/ArrowLeft";
import HorizontalLine from '../../components/hr-line/HorizontalLine';

export default function ServiceManagement() {

    //Obter dados serviços por salão
    const param = useParams();
    const salonId = param.salonId;
    const [ServicoCadastrado, SetServicoCadastrado] = useState([]); // Estado para armazenar dados 

    useEffect(() => {
        const fetchServiceData = async () => {
            const servicos = await SalonService.obterReservation(salonId);
            SetServicoCadastrado(servicos);
        }
        fetchServiceData();

    }, []);

    return (
        <>
            <Header />
            <HorizontalLine />
            <ArrowLeft />
            <div>
                <h2>Gerenciar Serviços</h2>
                <form>
                    {ServicoCadastrado ? (
                        ServicoCadastrado.map(servico => (
                            <div className='listServiceManagement'>
                                <div className='serviceManagement'><p >Serviço Cadastrado:</p></div>
                                <div className='dataServiceManagement'><p>{servico.name}</p></div>
                                <div className='serviceManagement'><p>Descrição do Serviço:</p></div>
                                <div className='dataServiceManagement'><p>{servico.description}</p></div>
                                <div className='serviceManagementLast'><p>duração:</p></div>
                                <div className='dataServiceManagementLast'><p>{servico.duration}</p></div>
                                <div className='buttonRemoveService'><button className='button7' type='submit'>Remover</button></div>

                            </div>
                        ))
                    ) : (<p>Carregando Serviços</p>)
                    }
                </form>
            </div>
        </>
    )
}