import React , { useState, useEffect }from "react";
import Header from "../../components/header/Header";
import Footer from '../../components/footer/Footer';
import { Button, Form, Alert } from "react-bootstrap";
import "./Payment.css";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth"; 
import HorizontalLine from "../../components/hr-line/HorizontalLine";


export default function Payment() {
  const location = useLocation();
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const { planName, planPrice } = location.state || {};
  const [username, setUsername] = useState("");
  const [number, setNumber] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [code, setCode] = useState("");
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid); 
      } else {
        navigate("/login"); 
      }
    });
  }, [navigate]);

  const handleValidateForm = async (e) => {
    e.preventDefault();

    if (!username || !number || !month || !year || !code) {
      setError("Por favor, preencha todos os campos.");
      return;
    }

    try {
      // Chamada para o endpoint que atualiza o `planName`
      const response = await fetch(`https://userservice-h4hn.onrender.com/api/user/plan/${userId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ planName }),
      });

      if (response.ok) {
        setUsername("");
        setNumber("");
        setMonth("");
        setYear("");
        setCode("");
        setError("");
        navigate("/payment-message");
      } else {
        const data = await response.json();
        setError(data.error || "Erro ao atualizar o plano.");
      }
    } catch (err) {
      console.error("Erro na atualização do plano:", err);
      setError("Erro ao conectar com o servidor.");
    }
  };


  return (
    <>
      <Header />
      <HorizontalLine />

      <div>
        <p className="plan-title text-center">Finalize seu pagamento</p>
        {error && (
          <Alert variant="danger" className="alert-danger">
            {error}
          </Alert>
        )}
        {planName && planPrice ? (
          <div className="price">
            <p>Plano selecionado: {planName}</p>
            <p>
              <b>Preço R$: {planPrice}</b>
            </p>
          </div>
        ) : (
          <p>Você não possui nada no seu carrinho</p>
        )}
      </div>

      {planName && planPrice && (
        <div className="form-payment">
          <Form className="text-center" onSubmit={handleValidateForm}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Nome do Cartão</Form.Label>
              <div className="d-flex justify-content-center">
                <Form.Control
                  type="text"
                  className="w-25"
                  name="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  onBlur={() => {
                    if (username) {
                      setError("");
                    }
                  }}
                />
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicNumber">
              <Form.Label>Número do Cartão</Form.Label>
              <div className="d-flex justify-content-center">
                <Form.Control
                  type="text"
                  className="w-25 mx2"
                  name="number"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)} 
                  onBlur={() => {
                    if (number) {
                      setError(""); 
                    }
                  }}
                />
              </div>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Data de Expiração</Form.Label>
              <div className="d-flex justify-content-center align-items-center">
                <Form.Control
                  as="select"
                  className="custom-select-size mx-2"
                  aria-label="Select Mês"
                  name="month"
                  value={month}
                  onChange={(e) => setMonth(e.target.value)} 
                  onBlur={() => { 
                    if (month) {
                      setError(""); 
                    }
                  }}
                >
                  <option value="" disabled selected>Mês</option>
                  <option value="1">Janeiro</option>
                  <option value="2">Fevereiro</option>
                  <option value="3">Março</option>
                  <option value="4">Abril</option>
                  <option value="5">Maio</option>
                  <option value="6">Junho</option>
                  <option value="7">Julho</option>
                  <option value="8">Agosto</option>
                  <option value="9">Setembro</option>
                  <option value="10">Outubro</option>
                  <option value="11">Novembro</option>
                  <option value="12">Dezembro</option>
                </Form.Control>

                <Form.Control
                  as="select"
                  className="custom-select-size mx-2"
                  aria-label="Select Ano"
                  name="year"
                  value={year}
                  onChange={(e) => setYear(e.target.value)} 
                  onBlur={() => { 
                    if (year) {
                      setError(""); 
                    }
                  }}
                >
                  <option value="" disabled selected>Ano</option>
                  {Array.from({ length: 10 }, (_, i) => {
                    const year = new Date().getFullYear() + i;
                    return (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    );
                  })}
                </Form.Control>
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicSecurityCode">
              <Form.Label>Código de Segurança</Form.Label>
              <div className="d-flex justify-content-center">
                <Form.Control 
                  type="text" 
                  className="w-25" 
                  name="code" 
                  value={code}
                  onChange={(e) => setCode(e.target.value)} 
                  onBlur={() => { 
                    if (code) {
                      setError(""); 
                    }
                  }} 
                />
              </div>
            </Form.Group>

            <div className="d-flex justify-content-center">
              <Button type="submit" className="w-25 mt-3 custom-btn">
                Pagar
              </Button>
            </div>
          </Form>
        </div>
      )}
      <Footer/>
    </>
  );
}
