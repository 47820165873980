import React, { useState, useEffect } from "react";
import Header from "../../../components/header/Header";
import Footer from '../../../components/footer/Footer';
import { Button, Form } from "react-bootstrap";
import ArrowLeft from "../../../components/arrow-left/ArrowLeft";
import { useNavigate, useParams } from "react-router-dom";
import "./EditPlan.css";

export default function EditBasicPlan() {
  const { planId } = useParams();
  const [plan, setPlan] = useState({
    name: "",
    price: "",
    features: "",
    duration: "",
  });
  const navigate = useNavigate();

  // Função para buscar o plano pelo ID
  useEffect(() => {
    async function fetchPlan() {
      try {
        const response = await fetch(
          `https://pmv-ads-2024-2-e4-proj-infra-t6-beauty.onrender.com/api/plan/${planId}`
        );
        if (!response.ok) {
          throw new Error("Erro ao buscar plano");
        }
        const data = await response.json();
        setPlan(data);
      } catch (error) {
        console.error(error);
        alert("Erro ao buscar plano");
      }
    }

    fetchPlan();
  }, [planId]);

  // Função para atualizar o plano
  const updatePlan = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://pmv-ads-2024-2-e4-proj-infra-t6-beauty.onrender.com/api/plan/${planId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(plan),
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Plano atualizado com sucesso:", result);
        alert("Plano atualizado com sucesso!");
        navigate("/adm-area");
      } else {
        const errorResult = await response.json();
        console.error("Erro ao atualizar plano:", errorResult);
        alert("Erro ao atualizar o plano: " + errorResult.message);
      }
    } catch (error) {
      console.error("Erro ao atualizar plano:", error);
      alert("Erro ao atualizar o plano");
    }
  };

  return (
    <div className="">
      <Header />
      <ArrowLeft />
      <h2 className="plan-title">Editar {plan.name}</h2>
      <Form onSubmit={updatePlan} className="m-auto">
        <div className="row justify-content-center">
          <div className="col-md-4">
            {" "}
            <Form.Group controlId="formBasicPlan" className="mb-4">
              <Form.Label>Nome do plano</Form.Label>
              <Form.Control
                type="text"
                value={plan.name}
                onChange={(e) => setPlan({ ...plan, name: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="formBasicPrice" className="mb-4">
              <Form.Label>Preço</Form.Label>
              <Form.Control
                type="text"
                value={plan.price}
                onChange={(e) => setPlan({ ...plan, price: e.target.value })}
              />
            </Form.Group>
            <Form.Group controlId="formBasicDuration" className="mb-4">
              <Form.Label>Duração</Form.Label>
              <Form.Control
                type="text"
                value={plan.duration}
                onChange={(e) => setPlan({ ...plan, duration: e.target.value })}
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Características</Form.Label>
              <Form.Control
                as="textarea"
                type="text"
                value={plan.features}
                onChange={(e) => setPlan({ ...plan, features: e.target.value })}
                rows={3}
              />
            </Form.Group>
            <Button className="create-btn mt-4" type="submit">
              Salvar
            </Button>
          </div>
        </div>
      </Form>
      <Footer />
    </div>
  );
}
