import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from '../../components/footer/Footer';
import { Button } from "react-bootstrap";
import "./AdminArea.css";
import { useNavigate } from "react-router-dom";
import HorizontalLine from "../../components/hr-line/HorizontalLine";


export default function AdminArea() {
  const Navigate = useNavigate();
  const [plans, setPlans] = useState([]);

  const HandleNavigatePlan = () => {
    Navigate("/create-plan");
  };

  // Função para buscar todos os planos
  async function GetAllPlans() {
    try {
      const response = await fetch("https://pmv-ads-2024-2-e4-proj-infra-t6-beauty.onrender.com/api/plans");
      if (!response.ok) {
        throw new Error("Erro ao buscar planos");
      }
      const data = await response.json();
      setPlans(data);
    } catch (error) {
      console.error("Erro ao buscar planos:", error);
      alert("Erro ao buscar planos");
    }
  }

  // Função para deletar um plano
  async function deletePlan(planId) {
    try {
      const response = await fetch(`https://pmv-ads-2024-2-e4-proj-infra-t6-beauty.onrender.com/api/plan/${planId}`, {
        method: "DELETE",
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Plano deletado com sucesso:", result);
        alert("Plano deletado com sucesso!");

        setPlans((prevPlans) => prevPlans.filter((plan) => plan.id !== planId));
      } else {
        const errorResult = await response.json();
        console.error("Erro ao deletar plano:", errorResult);
        alert("Erro ao deletar o plano: " + errorResult.message);
      }
    } catch (error) {
      console.error("Erro ao deletar plano:", error);
      alert("Erro ao deletar o plano");
    }
  }

  useEffect(() => {
    GetAllPlans();
  }, []);

  return (
    <>
      <Header />
      <HorizontalLine />
      <p className="plan-title text-center mb-4">Área do Administrador</p>
      <p className="plan-name">Criar novo plano</p>
      <Button className="create-btn mb-2" onClick={HandleNavigatePlan}>
        Criar
      </Button>
     
      <hr className="hr" />

      {plans.map((plan) => (
        <div
          className="plan-container d-flex align-items-center mb-5"
          key={plan.id}
          style={{
            width: "28%", 
            margin: "0 auto", 
          }}
        >
          <p
            className="plan-name mb-4"
            style={{ flexGrow: 1, marginRight: "20px", maxWidth: "60%" }}
          >
            {plan.name}
          </p>
          <div className="button-group d-flex">
            <Button
              className="btn-adm-yellow mb-4"
              onClick={() => Navigate(`/edit-plan/${plan.id}`)}
            >
              Editar
            </Button>
            <Button className="btn-adm-red mb-4" onClick={() => deletePlan(plan.id)}>
              Excluir
            </Button>
          </div>
        </div>
      ))}
      <Footer />
    </>
  );
}
