import {React, useEffect, useState} from "react";
import { Button } from "react-bootstrap";
import Image from "../../assets/images/make-hair.jpg";
import "./SalonBanner.css";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebaseConfig";


function SalonBanner() {
  const navigate = useNavigate();

  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
    });
  
    return () => unsubscribe(); 
  }, []);

  const handleNavigate = () => {
    if(user) {
        navigate('/search');
    }else {
        navigate('/login');
    }
  }

  return (
    <div className="container banner-container-salon">
      <div className="row align-items-start">
        <div className="col div-text">
          <h3 className="text-start mt-5">Encontre os melhores salões </h3>
          <h3 className="text-start">e centros de estética em um só lugar! </h3>
          <p className="text-title mt-4 text-start">
            Mais de 200 estabelecimentos cadastrados,
          </p>
          <p className="text-title text-start">
            prontos para oferecer o melhor cuidado para sua
          </p>
          <p className="text-title text-start">
            beleza. Agende com facilidade e descubra 
          </p>
          <p className="text-title text-start">
             uma experiência única.
          </p>
          <div className="text-start">
          <Button className="banner-button " onClick={handleNavigate}>
            Descubra Mais
          </Button>
          </div>
        </div>

        <div className="col container-text text-start">
          <img
            src={Image}
            alt="Beauty Model"
            className="img-fluid salon-banner-img"
          />
        </div>
      </div>
    </div>
  );
}

export default SalonBanner;
