import React, { useState } from "react";
import Header from "../../../components/header/Header";
import { Button, Form, Alert } from "react-bootstrap";
import Footer from '../../../components/footer/Footer';
import ArrowLeft from "../../../components/arrow-left/ArrowLeft";
import "./CreatePlan.css";


export default function CreatePlan() {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [features, setFeatures] = useState("");
  const [duration, setDuration] = useState("");
  const [error, setError] = useState(""); 

  // Função para lidar com o envio do formulário
  const handleCreatePlan = async (e) => {
    e.preventDefault();

    // Validação dos campos
    if (!name || !price || !features || !duration) {
      setError("Por favor, preencha todos os campos.");
      return; 
    }

    const planData = {
      name,
      price: parseFloat(price),
      duration,
      features: features.split(",").map((item) => item.trim()),
    };

    try {
      const response = await fetch("https://pmv-ads-2024-2-e4-proj-infra-t6-beauty.onrender.com/api/plan", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(planData),
      });

      if (response.ok) {
        const data = await response.json();
        alert(`Plano criado com sucesso!`);

        setName("");
        setPrice("");
        setDuration("");
        setFeatures("");
        setError(""); 
       
      } else {
        alert("Erro ao criar plano");
      }
    } catch (error) {
      console.error("Erro ao criar plano:", error);
      alert("Erro ao criar plano");
    }
  };

  return (
    <>
      <Header />
      <ArrowLeft />
      <p className="plan-title text-center">Criar novo plano</p>

      
      {error && <Alert variant="danger" className="alert-danger">{error}</Alert>}
      

      <Form onSubmit={handleCreatePlan}>
        <Form.Group className="mb-3" controlId="formBasicPlan">
          <Form.Label>Nome do plano</Form.Label>
          <div className="d-flex justify-content-center">
            <Form.Control
              name="plan"
              type="text"
              className="w-25"
              value={name}
              onChange={(e) => setName(e.target.value)} 
              onBlur={() => { 
                if (name) {
                  setError(""); 
                }
              }}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPrice">
          <Form.Label>Preço</Form.Label>
          <div className="d-flex justify-content-center">
            <Form.Control
              name="price"
              type="text"
              className="w-25"
              value={price}
              onChange={(e) => setPrice(e.target.value)} 
              onBlur={() => { 
                if (price) {
                  setError(""); 
                }
              }}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicTime">
          <Form.Label>Duração</Form.Label>
          <div className="d-flex justify-content-center">
            <Form.Control
              name="duration"
              type="text"
              className="w-25"
              value={duration}
              onChange={(e) => setDuration(e.target.value)} 
              onBlur={() => {
                if (duration) {
                  setError(""); 
                }
              }}
            />
          </div>
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Características</Form.Label>
          <div className="d-flex justify-content-center">
            <Form.Control
              name="features"
              as="textarea"
              rows={3}
              className="w-25"
              value={features}
              onChange={(e) => setFeatures(e.target.value)} 
              onBlur={() => { 
                if (features) {
                  setError("");
                }
              }}
            />
          </div>
        </Form.Group>

        <Button type="submit" className="create-btn mt-3">
          Criar
        </Button>
      </Form>
      <Footer />
    </>
  );
}