import React, { useState, useEffect } from "react";
import Header from "../../components/header/Header";
import ArrowLeft from "../../components/arrow-left/ArrowLeft";
import { Button } from "react-bootstrap";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import HorizontalLine from "../../components/hr-line/HorizontalLine";
import "./UserArea.css";


export default function UserArea() {
  const [user, setUser] = useState(null);
  const [userPlan, setUserPlan] = useState("");
  const [schedules, setSchedules] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const userId = currentUser.uid;
        await fetchUserData(userId);
        await fetchSchedules(userId);
      } else {
        navigate("/login");
      }
    });
  }, [navigate]);

  const fetchUserData = async (userId) => {
    try {
      const response = await fetch(`https://userservice-h4hn.onrender.com/api/user/${userId}`);
      if (!response.ok) throw new Error("Erro ao buscar dados do usuário");

      const userData = await response.json();
      setUser(userData.UserName);
      setUserPlan(userData.planName);
    } catch (error) {
      console.error("Erro ao buscar dados do usuário:", error);
    }
  };

  const cancelPlan = async () => {
    if (!window.confirm("Tem certeza que deseja cancelar seu plano?")) return;

    try {
      const auth = getAuth();
      const userId = auth.currentUser?.uid;
      if (!userId) return;

      // Atualiza o plano para "" no backend (cancelamento)
      const response = await fetch(
        `https://pmv-ads-2024-2-e4-proj-infra-t6-beauty.onrender.com/api/user/plan/${userId}`, // A URL do endpoint
        {
          method: "PUT", // Usando PUT para atualizar o plano do usuário
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            planName: "", // Envia uma string vazia para cancelar o plano
          }),
        }
      );

      if (!response.ok) throw new Error("Erro ao cancelar o plano");

      // Atualiza o estado do plano após o cancelamento
      setUserPlan(""); // Limpa o plano do usuário
      alert("Plano cancelado com sucesso!");
    } catch (error) {
      console.error("Erro ao cancelar o plano:", error);
    }
  };

  const fetchSchedules = async (userId) => {
    try {
      const response = await fetch(
        `https://pmv-ads-2024-2-e4-proj-infra-t6-beauty-46ik.onrender.com/api/schedules/user/${userId}`
      );
      if (!response.ok) throw new Error("Erro ao buscar agendamentos");

      const scheduleData = await response.json();

      // Buscar o nome do salão
      const schedulesWithSalonNames = await Promise.all(
        scheduleData.map(async (schedule) => {
          const salonResponse = await fetch(
            `https://pmv-ads-2024-2-e4-proj-infra-t6-beauty-qbcl.onrender.com/api/salon/${schedule.salonId}`
          );
          if (!salonResponse.ok) throw new Error("Erro ao buscar o salão");

          const salonData = await salonResponse.json();
          return {
            ...schedule,
            salonName: salonData.salon.companyName,
          };
        })
      );

      setSchedules(schedulesWithSalonNames);
    } catch (error) {
      console.error(error);
    }
  };

  const cancelSchedule = async (scheduleId) => {
    if (!window.confirm("Tem certeza que deseja cancelar este agendamento?"))
      return;

    try {
      const response = await fetch(
        `https://pmv-ads-2024-2-e4-proj-infra-t6-beauty-46ik.onrender.com/api/schedules/${scheduleId}/cancelByUser`,
        { method: "PUT" }
      );
      if (!response.ok) throw new Error("Erro ao cancelar o agendamento");

      setSchedules((prevSchedules) =>
        prevSchedules.filter((schedule) => schedule.scheduleId !== scheduleId)
      );
      alert("Agendamento cancelado com sucesso!");
    } catch (error) {
      console.error(error);
    }
  };

  const statusTranslation = {
    pending: "Pendente",
    confirmed: "Confirmado",
    cancelledByUser: "Cancelado",
    cancelledBySalon: "Cancelado",
  };

  return (
    <>
      <Header />
      <HorizontalLine />
      <ArrowLeft />
      <div className="user-info">
        <p className="user-title text-center">Olá, {user}!</p>
        <div className="user-plan">
          <p className="plan-name">
            Meu plano: {userPlan || "Nenhum plano cadastrado"}
          </p>
          <div className="plan-btn-group d-flex">
            <Button
              className="plan-btn-yellow mb-4"
              onClick={() => navigate("/plans")}
            >
              Alterar plano
            </Button>
            <Button
              className="plan-btn-red mb-4"
              onClick={cancelPlan}
              disabled={!userPlan} // Desativa o botão se não houver plano
            >
              Cancelar plano
            </Button>
          </div>
        </div>
        <hr className="line" />
      </div>

      <p className="user-title text-center">Meus agendamentos</p>
      <ul className="schedules">
        {schedules.length > 0 ? (
          schedules.map((schedule) => (
            <li className="schedule-item" key={schedule.scheduleId}>
              <p>Nome do salão: {schedule.salonName}</p>
              <p>Data: {schedule.date}</p>
              <p>Hora: {schedule.time}</p>
              <p>Status: {statusTranslation[schedule.status]}</p>
              <Button
                className="schedule-btn-yellow mb-4"
                onClick={() =>
                  navigate(
                    `/schedule/${schedule.salonId}/${schedule.serviceId}`
                  )
                }
              >
                Ver serviço
              </Button>
              <Button
                className="schedule-btn-red mb-4"
                onClick={() => cancelSchedule(schedule.scheduleId)}
                disabled={
                  schedule.status === "cancelledByUser" ||
                  schedule.status === "cancelledBySalon"
                }
              >
                Cancelar
              </Button>
            </li>
          ))
        ) : (
          <p>Você não tem agendamentos.</p>
        )}
      </ul>
    </>
  );
}
