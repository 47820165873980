import React, { useEffect, useState } from "react";
import { auth } from "../../firebaseConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import "./Header.css";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { faShoppingCart, faUser, faSearch } from '@fortawesome/free-solid-svg-icons';
import './Header.css';
import { Button } from "react-bootstrap";
import { SalonService } from '../../services/SalonService';

function Header() {
  const [user, setUser] = useState(null);
  const [userName, setUserName] = useState("");
  const navigate = useNavigate();
  const [salon, setSalon] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
        setUserName(authUser.displayName || authUser.email);
      } else {
        setUser(null);
        setUserName("");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchSalonData = async () => {
      if(user){
        const data = await SalonService.obterSalonByUserId(user.uid);
        setSalon(data);
      }
    }
    fetchSalonData();
}, [user]);


  const isAdmin = user?.email && user.email.startsWith("adm");

  // Função para realizar o logout
  const handleLogout = async () => {
    try {
      await auth.signOut();
      setUser(null);
      setUserName("");
      navigate("/login");

    } catch (error) {
      console.error("Erro ao sair:", error);
    }
  };

  return (
    <>
      <Navbar expand="lg" className="navbar navbar-expand-lg custom-navbar">
        <Container>
          <Navbar.Brand href="/home-page">
            <b>BeautyPass</b>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              <Nav.Link href="/home-page">Home</Nav.Link>
              {!user && (
                <>
                  <Nav.Link href="/company-registration">Para empresas</Nav.Link>
                  <Nav.Link href="/user-registration">Para usuários</Nav.Link>
                </>
              )}
              <Nav.Link href="/plans">Planos e preços</Nav.Link>
              {user &&<Nav.Link href="/search">Buscar empresas</Nav.Link>}
              {user &&<Nav.Link href="/support">Fale conosco</Nav.Link>}
            </Nav>
            <Nav className="">
            {user && <Button href="#" className='logout-btn' onClick={handleLogout}>Sair</Button>}
              <Nav.Link href="/search">
                <FontAwesomeIcon icon={faSearch} />
              </Nav.Link>
              <Nav.Link href={
                isAdmin 
                ? "/adm-area" 
                : salon 
                  ? `/company-area/${salon.salonId}` 
                  : user 
                  ? "/user-area" 
                  : "/login"
              }>
                <FontAwesomeIcon icon={faUser} />
              </Nav.Link>
              <Nav.Link href="/payment">
                <FontAwesomeIcon icon={faShoppingCart} />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

    </>
  );
}

export default Header;
