import {React, useEffect, useState} from 'react';
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Lashes from "../../assets/images/lashes.jpg";
import Nails from "../../assets/images/nails.jpg";
import Hair from "../../assets/images/hair.jpg";
import Estetic from '../../assets/images/estetica.jpg'
import Card from "react-bootstrap/Card";
import './HomeImages.css';
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebaseConfig";


function ShapeExample() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
    });
  
    return () => unsubscribe(); 
  }, []);

  const handleNavigate = () => {
    if(user) {
        navigate('/search');
    }else {
        navigate('/login');
    }
  }

  return (
    <>
    <Container className="images-container">
            <h3 className="mt-5 mb-5 title-services">Todos os serviços que você precisa</h3>
            <p className="see-more" onClick={handleNavigate}>Ver mais</p>
            <Row className="d-flex align-items-stretch"> 
                <Col xs={12} sm={6} md={3} className="mb-4">
                    <Card style={{ height: "300px" }}>
                        <Card.Img
                            style={{ height: "300px", objectFit: "cover" }}
                            variant="top"
                            src={Lashes}
                            className="fluid-image"
                        />
                    </Card>
                    <p className="mt-3"><b>Cílios</b></p>
                </Col>

                <Col xs={12} sm={6} md={3} className="mb-4">
                    <Card style={{ height: "300px" }}>
                        <Card.Img
                            style={{ height: "300px", objectFit: "cover" }}
                            variant="top"
                            src={Hair}
                            className="fluid-image"
                        />
                    </Card>
                    <p className="mt-3"><b>Cabelo</b></p>
                </Col>

                <Col xs={12} sm={6} md={3} className="mb-4">
                    <Card style={{ height: "300px" }}>
                        <Card.Img
                            style={{ height: "300px", objectFit: "cover" }}
                            variant="top"
                            src={Nails}
                            className="fluid-image"
                        />
                    </Card>
                    <p className="mt-3"><b>Unhas</b></p>
                </Col>

                <Col xs={12} sm={6} md={3} className="mb-4">
                    <Card style={{ height: "300px" }}>
                        <Card.Img
                            style={{ height: "300px", objectFit: "cover" }}
                            variant="top"
                            src={Estetic}
                            className="fluid-image"
                        />
                    </Card>
                    <p className="mt-3"><b>Estética</b></p>
                </Col>
            </Row>
        </Container>
    </>
  );
}

export default ShapeExample;
