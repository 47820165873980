import React, { useState, useCallback } from 'react';
import './UserRegistration.css';
import imagemBeleza from './assets/ServicoDeBeleza.jpg';
import 'font-awesome/css/font-awesome.min.css';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebaseConfig';


function UserRegistration() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    cpf: '',
    endereco: '',
    telefone: '',
    senha: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    
    try {
      // Preparar os dados para enviar via POST
      const userData = {
        UserAdress: formData.endereco,
        cpf: formData.cpf,
        UserPhone: formData.telefone,
        UserName: formData.nome,
        UserEmail: formData.email,
        passwordHash: formData.senha 
      };

      // Enviar os dados para o backend via fetch
      const response = await fetch('https://userservice-h4hn.onrender.com/api/user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData)
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Usuário cadastrado:', data);
        setMessage('Usuário cadastrado com sucesso!');
        navigate("/login");
      } else {
        console.log('Erro ao cadastrar usuário:', response.status);
        setMessage('Erro ao cadastrar usuário. Tente novamente.');

      }
    } catch (error) {
      console.error('Erro ao cadastrar usuário:', error);
      setMessage('Erro ao cadastrar usuário. Tente novamente.');
    } finally {
      setLoading(false);
    }
  
    setFormData({
      nome: '',
      email: '',
      cpf: '',
      endereco: '',
      telefone: '',
      senha: ''
    });
  };
  


  return (
    <div className="user-registration-container">
      <button className="back-button" onClick={() => navigate('/')}>
        <i className="fa fa-arrow-left"></i>
      </button>
      <div className="form-container">
        <h2>Cadastre-se</h2>
        <form onSubmit={handleSubmit}>
          <label>Nome</label>
          <input
           className="input-login" 
            type="text" 
            name="nome" 
            value={formData.nome} 
            onChange={handleChange} 
            required 
          />
          <label>Email</label>
          <input 
           className="input-login"
            type="email" 
            name="email" 
            value={formData.email} 
            onChange={handleChange} 
            required 
          />
          <label>Cpf</label>
          <input 
          className="input-login"
            type="text" 
            name="cpf" 
            value={formData.cpf} 
            onChange={handleChange} 
            required 
          />
          <label>Endereço</label>
          <input 
          className="input-login"
            type="text" 
            name="endereco" 
            value={formData.endereco} 
            onChange={handleChange} 
            required 
          />
          <label>Telefone</label>
          <input 
          className="input-login"
            type="text" 
            name="telefone" 
            value={formData.telefone} 
            onChange={handleChange} 
            required 
          />
          <label>Senha</label>
          <div className="password-container">
            <input 
            className="input-login"
              type={showPassword ? 'text' : 'password'} 
              name="senha" 
              value={formData.senha} 
              onChange={handleChange} 
              required 
            />
            <button 
              type="button" 
              onClick={() => setShowPassword(!showPassword)}
              className="toggle-password"
            >
              <i className={showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'}></i>
            </button>
          </div>
          <button type="submit" className='button-cadastro' disabled={loading}>Cadastrar</button>
          {message && <p className="success-message">{message}</p>}
          {error && <p className="error-message">{error.message}</p>}
        </form>
      </div>
      <div className="image-container">
        <img src={imagemBeleza} alt="Serviço de beleza" />
      </div>
    </div>
  );
}

export default UserRegistration;