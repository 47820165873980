import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';


// Configurações do Firebase
const firebaseConfig = {
  apiKey: "AIzaSyDsYSHCIdB1TsKFaJ4L6aJdnmX5BUaRozg",
  authDomain: "beautypass-b116c.firebaseapp.com",
  projectId: "beautypass-b116c",
  storageBucket: "beautypass-b116c.appspot.com",
  messagingSenderId: "84339843954",
  appId: "1:84339843954:web:c19e2930eaf083aa3bb3eb",
  measurementId: "G-P2MNWTLPBV"
};

// Inicializa o Firebase
const app = initializeApp(firebaseConfig);

// Inicializa o Auth e o Firestore usando a nova API
export const auth = getAuth(app);

