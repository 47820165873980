import React, { useState } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import imagemBeleza from "./assets/ServicoDeBeleza.jpg";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { auth } from "../../firebaseConfig";
import { Link } from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Chama o hook useSignInWithEmailAndPassword
  const [signInWithEmailAndPassword, user, loading, error] =
    useSignInWithEmailAndPassword(auth);

  const handleSignIn = async (event) => {
    event.preventDefault(); // Evita o comportamento padrão de submissão do formulário
    setErrorMessage(""); // Reseta a mensagem de erro

    if (!email || !password) {
      setErrorMessage("Por favor, preencha todos os campos.");
      return;
    }

    // Tenta fazer o sign in
    try {
      const userCredential = await signInWithEmailAndPassword(email, password);

      // Verifica se o usuário foi autenticado
      if (userCredential && userCredential.user) {
        const token = await userCredential.user.getIdToken(); // Obtém o token JWT
        localStorage.setItem('token', token); // Armazena o token (ou use cookies se preferir)
        navigate(`/home-page`); // Redireciona para a página do usuário
      }
    } catch (signInError) {
      // Define a mensagem de erro se houver
      setErrorMessage(signInError.message);
    }
  };

  const welcomeMessage = user ? <p>Bem-vindo, {user.email}!</p> : null;

  return (
    <div className="login-container">
      <button className="back-button" onClick={() => navigate("/")}>
        <i className="fa fa-arrow-left"></i>
      </button>

      <div className="form-container">
        <h2>Acesse a sua conta</h2>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        {error && <p className="error-message">{error.message}</p>}
        {welcomeMessage}

        <form onSubmit={handleSignIn}>
          <label>Email</label>
          <input
          className="input-login"
            type="email"
            placeholder="Digite seu email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <label>Senha</label>
          <input
          className="input-login"
            type="password"
            placeholder="Digite sua senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          <button className="login-btn" type="submit" disabled={loading}>
            {loading ? "Carregando..." : "Entrar"}
          </button>
        </form>
        <p className="mt-4">
          Não possui uma conta? <Link to="/user-registration">Cadastre-se</Link>
        </p>
      </div>

      <div className="image-container">
        <img src={imagemBeleza} alt="Serviço de Beleza" />
      </div>
    </div>
  );
}

export default Login;
