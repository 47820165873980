import React from "react";
import Header from "../../../components/header/Header";
import { Alert, Col, Row, Container } from "react-bootstrap";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './Message.css';

export default function Message() {
  return (
    <>
      <Header />
      <Container className="container-message">
        <Row className="justify-content-center">
          <Col xs={12} md={8} lg={6}>
            <Alert variant="success" className="alert-message">
              <FontAwesomeIcon
                icon={faCheckCircle}
                size="3x"
                className="mb-3"
              />
              <Alert.Heading>Pagamento realizado com sucesso!</Alert.Heading>
            </Alert>
          </Col>
        </Row>
      </Container>
    </>
  );
}
