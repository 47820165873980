import React, { useState } from 'react';
import '@fontsource/roboto';
import { TextField, Button, Container, Box, Paper, FormControl, Typography } from '@mui/material';
import './Support.css';
import HorizontalLine from '../../components/hr-line/HorizontalLine';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

const SupportForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Simula envio com um pequeno atraso
    setTimeout(() => {
      alert('Formulário enviado com sucesso!');
      setFormData({ firstName: '', lastName: '', email: '', message: '' }); // Limpa os campos do formulário
      setIsSubmitting(false);
    }, 1000);
  };

  return (
    <>
      <Header />
      <HorizontalLine />
      <Container maxWidth="sm" className="container-center">
        <Typography
          variant="h5"
          gutterBottom
          className="page-title"
          sx={{ fontWeight: 'bold', mb: 5 }}
        >
          Entre em contato com a gente
        </Typography>
        <Paper elevation={2} className="form-paper" sx={{ borderRadius: 6, padding: 6 }}>
          <form onSubmit={handleSubmit}>
            <Box mb={5}>
              <FormControl fullWidth required>
                <TextField
                  variant="outlined"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="form-field"
                  placeholder="Nome"
                  required
                  inputProps={{
                    pattern: "[A-Za-zÀ-ÿ ]+",
                    maxLength: 30
                  }}
                  InputProps={{
                    sx: { borderRadius: 5 }
                  }}
                />
              </FormControl>
            </Box>
            <Box mb={5}>
              <FormControl fullWidth required>
                <TextField
                  variant="outlined"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="form-field"
                  placeholder="Sobrenome"
                  required
                  inputProps={{
                    pattern: "[A-Za-zÀ-ÿ ]+",
                    maxLength: 30
                  }}
                  InputProps={{
                    sx: { borderRadius: 5 }
                  }}
                />
              </FormControl>
            </Box>
            <Box mb={5}>
              <FormControl fullWidth required>
                <TextField
                  variant="outlined"
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="form-field"
                  placeholder="E-mail"
                  required
                  InputProps={{
                    sx: { borderRadius: 5 }
                  }}
                />
              </FormControl>
            </Box>
            <Box mb={5}>
              <FormControl fullWidth required>
                <TextField
                  variant="outlined"
                  multiline
                  rows={4}
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="form-field"
                  placeholder="Mensagem"
                  required
                  inputProps={{
                    minLength: 10,
                    maxLength: 500
                  }}
                  InputProps={{
                    sx: { borderRadius: 5 }
                  }}
                />
              </FormControl>
            </Box>
            <Button
              type="submit"
              variant="contained"
              className="submit-button"
              disabled={isSubmitting}
              sx={{
                backgroundColor: '#d4c255',
                color: 'black',
                borderRadius: 4,
                '&:hover': {
                  backgroundColor: '#b8a645'
                }
              }}
            >
              {isSubmitting ? 'Enviando...' : 'Enviar'}
            </Button>
          </form>
        </Paper>
      </Container>
      <Footer />
    </>
  );
};

export default SupportForm;
