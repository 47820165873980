import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { auth } from "../../firebaseConfig";
import Header from "../../components/header/Header";
import ArrowLeft from "../../components/arrow-left/ArrowLeft";
import HorizontalLine from "../../components/hr-line/HorizontalLine";

import "./Schedule.css";

export default function Schedule() {
  const [selectedSlot, setSelectedSlot] = useState(null); // Estado para o slot selecionado
  const [serviceData, setServiceData] = useState(null); // Estado para os dados do serviço
  const [error, setError] = useState(null);  // Estado para mensagens de erro

  const { salonId, serviceId } = useParams(); // Recebe salonId e serviceId da URL
  const [userId, setUserId] = useState(null);

  const navigate = useNavigate();

  // Obter o Id do usuário autenticado
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid); // Armazena o ID do usuário autenticado
      } else {
        setUserId(null); // Reseta se não houver usuário
      }
    });

    return () => unsubscribe(); // Limpa o listener quando o componente é desmontado
  }, []);

  // Função para buscar dados do serviço
  useEffect(() => {
    const fetchServiceData = async () => {
      try {
        //console.log(`Fetching data for serviceId: ${serviceId}`);
        const response = await fetch(
          `https://servicedetails-vsd2.onrender.com/api/services/${serviceId}`
        );
        if (!response.ok) {
          throw new Error("Erro ao buscar dados do serviço");
        }
        const data = await response.json();
        //console.log("Dados do serviço recebidos:", data);
        setServiceData(data); // Atualiza o estado com os dados do serviço
      } catch (error) {
        console.error(error);
      }
    };
    fetchServiceData();
  }, [serviceId]);

  const handleSlotChange = (day, time) => {
    setSelectedSlot({ day, time }); // Armazena o dia e o horário selecionado
  };

  const handleSubmit = async () => {
    if (!userId) {
      // Se o usuário não estiver logado, redireciona para a página de login
      alert("Você precisa estar logado para agendar um serviço.");
      navigate("/login"); // Redireciona para a página de login
      return;
    }

    if (!selectedSlot) {
      setError("Por favor, selecione um horário antes de agendar.");
      return;
    }

    const { day, time } = selectedSlot;

    /* Log dos dados que serão enviados para a API
    console.log("Dados para criação do agendamento:", {
      userId,
      salonId,
      serviceId,
      date: day,
      time,
    });*/

    // Chamada para criar o agendamento
    try {
      const response = await fetch("https://pmv-ads-2024-2-e4-proj-infra-t6-beauty-46ik.onrender.com/api/schedules", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          salonId,
          serviceId,
          date: day,
          time,
        }),
      });

      // Resposta da API
      //console.log("Resposta da API para criação do agendamento:", response);
      //console.log("Status da resposta:", response.status);

      if (!response.ok) {
        throw new Error("Erro ao criar agendamento");
      }

      const result = await response.json();
      //console.log("Agendamento criado com sucesso:", result);
      alert("Agendamento criado com sucesso!");
    } catch (error) {
      console.error("Erro ao criar agendamento:", error);
      alert("Erro ao criar agendamento");
    }
  };

  // Verifica se há slots disponíveis
  const hasAvailableSlots =
    serviceData &&
    serviceData.availableSlots.some((slot) =>
      slot.slots.some((timeSlot) => timeSlot.isAvailable)
    );

  return (
    <>
      <Header />
      <HorizontalLine />
      <ArrowLeft />
      <div className="main-container">
        <div className="service-selection">
          <h1>Serviço selecionado</h1>
          {serviceData ? (
            <ul className="service-item">
              <li>{serviceData.name}</li> {/* Nome do serviço */}
              <li>{serviceData.duration} minutos</li> {/* Duração do serviço */}
            </ul>
          ) : (
            <p>Carregando dados do serviço...</p>
          )}
        </div>
        <div className="slot-selection">
          <h1>Selecione dia e horário</h1>
          <hr className="line" />
          <ul className="service-slots">
            {serviceData &&
              serviceData.availableSlots
                .filter((slot) =>
                  slot.slots.some((timeSlot) => timeSlot.isAvailable)
                ) // Filtra dias com pelo menos um slot disponível
                .map((slot) => (
                  <div key={slot.day}>
                    <h2>{slot.day}</h2> {/* Renderiza o dia */}
                    <ul className="slot-time">
                      {slot.slots
                        .filter((timeSlot) => timeSlot.isAvailable) // Filtra apenas os slots disponíveis
                        .map((timeSlot) => (
                          <li key={timeSlot.time}>
                            <label className="slot-radio">
                              <input
                                type="radio"
                                value={timeSlot.time}
                                checked={
                                  selectedSlot?.time === timeSlot.time &&
                                  selectedSlot?.day === slot.day
                                } // Verifica se o slot é o selecionado
                                onChange={() =>
                                  handleSlotChange(slot.day, timeSlot.time)
                                } // Chama a função ao mudar a seleção
                              />
                              {timeSlot.time} {/* Renderiza o horário */}
                            </label>
                          </li>
                        ))}
                    </ul>
                  </div>
                ))}
            {/* Verifica se não há slots disponíveis */}
            {serviceData && !hasAvailableSlots && (
              <p>Não há horários disponíveis para este serviço.</p>
            )}
          </ul>
          {/* Oculta o botão se não houver slots disponíveis */}
          {hasAvailableSlots && (
            <button className="schedule-btn" onClick={handleSubmit}>
              Agendar
            </button>
          )}
        </div>
      </div>
    </>
  );
}
