import React from "react";
import Header from "../../components/header/Header";
import Banner from "../../components/home-banner/Banner";
import PricingCard from "../../components/price-card/PricingCard";
import { useNavigate } from "react-router-dom";
import "./HomePage.css";
import HomeImage from '../../components/home-images/HomeImges';
import SalonBanner from "../../components/salon-banner/SalonBanner";
import Footer from '../../components/footer/Footer';

export default function HomePage() {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/plans");
  };

  return (
    <>
      <Header />
      <Banner />
      <HomeImage />
      <SalonBanner />
      <h3 className="choose-plan mt-5 mb-5">Escolha o seu plano</h3>
      <div
        className="custom-margin"
      >
        <div className="d-flex justify-content-center">
          <PricingCard
            title="BÁSICO"
            priceMain="29,90"
            priceSub="/mês"
            backgroundColor="#65C9E0"
            btnText="Ver mais"
            onClick={handleNavigate}
          />
        </div>
        <div className="d-flex justify-content-center">
          <PricingCard
            title="SILVER"
            priceMain="69,90"
            priceSub="/mês"
            backgroundColor="#7B61FF"
            btnText="Ver mais"
            onClick={handleNavigate}
          />
        </div>
        <div className="d-flex justify-content-center">
          <PricingCard
            title="GOLD"
            priceMain="99,90"
            priceSub="/mês"
            backgroundColor="#FA8CFF"
            btnText="Ver mais"
            onClick={handleNavigate}
          />
        </div>
      </div>
      <p className="text-cancell">*Cancele quando quiser, sem multa</p>
      <Footer />
    </>
  );
}
