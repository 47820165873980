import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom'; 
import './ArrowLeft.css';

export default function ArrowLeft(){
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    return(
        <>
        <div className="div-arrow-left">
        <FontAwesomeIcon icon={faArrowLeft}  onClick={handleBack} 
        style={{ cursor: 'pointer' }} />
        </div>
        </>
    ) 
}

