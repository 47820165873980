import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import PricingCard from "../../components/price-card/PricingCard";
import Footer from '../../components/footer/Footer';
import "./SubscriptionPlans.css";
import { useNavigate } from "react-router-dom";
import HorizontalLine from "../../components/hr-line/HorizontalLine";

export default function SubscriptionPlans({ title, priceMain, priceSub }) {
  const navigate = useNavigate();

  const handleSelectPlan = (planName, planPrice) => {
    navigate("/payment", {
      state: { planName: planName, planPrice: planPrice },
    });
  };

  const [plan1, setPlan1] = useState(null);
  const [plan2, setPlan2] = useState(null);
  const [plan3, setPlan3] = useState(null);

  // Função para buscar o plano pelo ID
  async function GetPlanById(planId) {
    try {
      const response = await fetch(`https://pmv-ads-2024-2-e4-proj-infra-t6-beauty.onrender.com/api/plan/${planId}`);
      if (!response.ok) {
        throw new Error("Erro ao buscar o plano específico");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Erro ao buscar o plano específico:", error);
      return null;
    }
  }

  useEffect(() => {
    const fetchPlans = async () => {
      const planIdBasic = "5hvoqO3h7JksOloVv0i7";
      const planIdSilver = "zt3qeWlbpUZxSkxq8Mff";
      const planIdGold = "mLMqvoElTWYId3kfv9Dk";

      const [fetchedPlan1, fetchedPlan2, fetchedPlan3] = await Promise.all([
        GetPlanById(planIdBasic),
        GetPlanById(planIdSilver),
        GetPlanById(planIdGold),
      ]);

      console.log("Planos buscados:", fetchedPlan1, fetchedPlan2, fetchedPlan3);
      setPlan1(fetchedPlan1);
      setPlan2(fetchedPlan2);
      setPlan3(fetchedPlan3);
    };

    fetchPlans();
  }, []);

  return (
    <>
      <Header />
      <HorizontalLine />
      <p className="title">Selecione o seu plano mensal</p>
      <div className="flex-container cards-container">
        <div className="row justify-content-center">
          <div className="col-md-3 d-flex justify-content-center p-0">
            {plan1 ? (
              <PricingCard
                title={plan1.name}
                priceMain={plan1.price}
                priceSub="/mês"
                backgroundColor="#65C9E0"
                btnText='Assinar'
                onSelect={(name, price) => handleSelectPlan(name, price)}
              />
            ) : (
              <p>Carregando plano básico...</p>
            )}
          </div>
          <div className="col-md-4">
            <p className="description-text">
              O Plano Básico é perfeito para quem busca uma rotina de cuidados
              pessoais sem complicações. Com uma escova e uma manicure por mês,
              você se mantém elegante e bem cuidado. Aproveite também 20% de
              desconto em serviços adicionais, ideal para ocasiões especiais ou
              quando você quer um cuidado extra. Cancele quando quiser, sem multa
            </p>
          </div>
        </div>

        <hr className="divider" />

        <div className="row justify-content-center">
          <div className="col-md-3 d-flex justify-content-center">
            {plan2 ? (
              <PricingCard
                title={plan2.name}
                priceMain={plan2.price}
                priceSub="/mês"
                backgroundColor="#7B61FF"
                btnText='Assinar'
                onSelect={(name, price) => handleSelectPlan(name, price)}
              />
            ) : (
              <p>Carregando plano Silver...</p>
            )}
          </div>
          <div className="col-md-4">
            <p className="description-text">
              O Plano Silver oferece um toque adicional de cuidado para quem
              deseja se mimar um pouco mais. Com duas escovas e uma manicure e
              pedicure mensais, você terá sempre uma aparência impecável. Desfrute de um desconto de 30% em serviços adicionais,
              tornando suas visitas ainda mais agradáveis. Cancele quando quiser, sem multa.
            </p>
          </div>
        </div>

        <hr className="divider" />

        <div className="row justify-content-center">
          <div className="col-md-3 d-flex justify-content-center">
            {plan3 ? (
              <PricingCard
                title={plan3.name}
                priceMain={plan3.price}
                priceSub="/mês"
                backgroundColor="#FA8CFF"
                btnText='Assinar'
                onSelect={(name, price) => handleSelectPlan(name, price)}
              />
            ) : (
              <p>Carregando plano Gold...</p>
            )}
          </div>
          <div className="col-md-4">
            <p className="description-text">
              O Plano Gold é a escolha ideal para quem deseja um cuidado
              completo e luxuoso. Com três escovas mensais, um corte de cabelo e
              uma manicure e pedicure, você estará sempre radiante. Além disso,
              aproveite 30% de desconto em serviços adicionais, permitindo que
              você se cuide da cabeça aos pés. Cancele quando quiser, sem multa.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
