import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Rating from '@mui/material/Rating';
import { Grid2, TextField } from '@mui/material';
import './Ratings.css';
import axios from 'axios';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid rgba(0, 0, 0, 0.5)',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
    p: 4,
};

export default function Ratings() {
    const [open, setOpen] = React.useState(false);
    const [rating, setRating] = React.useState(5);
    const [comment, setComment] = React.useState('');
    const maxChars = 500;

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChangeComment = (event) => {
        setComment(event.target.value);
    };

    const handleChangeRating = (event, newRating) => {
        setRating(newRating);
    };

    const handleSubmit = async () => {
        try {
            const data = {
                userId: 'USER_ID',      // Substituir pelo ID do usuário autenticado
                salonId: 'SALON_ID',    // Substituir pelo ID do salão
                rating,
                comment,
            };

            const response = await axios.post('https://ratingservice-xub2.onrender.com/api/ratings', data);
            console.log(response.data.message);
            handleClose();
        } catch (error) {
            console.error('Erro ao enviar avaliação:', error);
        }
    };

    return (
        <>
            <Button onClick={handleOpen}>Open modal</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid2>
                        <Rating
                            defaultValue={5}
                            onChange={handleChangeRating}
                            value={rating}
                        />
                    </Grid2>
                    <Grid2 style={{ marginTop: "2rem" }}>
                        <TextField
                            id="fullWidth"
                            label="Avalie como foi sua experiência aqui!"
                            fullWidth
                            value={comment}
                            onChange={handleChangeComment}
                            inputProps={{ maxLength: maxChars }}
                        />
                    </Grid2>
                    <Grid2 container justifyContent="space-between" style={{ marginTop: '0.5rem' }}>
                        <Typography variant="caption" color="textSecondary">Opcional</Typography>
                        <Typography variant="caption" color="textSecondary">{`${comment.length}/${maxChars}`}</Typography>
                    </Grid2>
                    <Grid2 container justifyContent="space-between" style={{ marginTop: "2rem" }}>
                        <Grid2 item xs={6} >
                            <Button className="cancel-button" onClick={handleClose}>
                                Cancelar
                            </Button>
                        </Grid2>
                        <Grid2 item xs={6}>
                            <Button className="save-button" onClick={handleSubmit}>
                                Enviar
                            </Button>
                        </Grid2>
                    </Grid2>
                </Box>
            </Modal>
        </>
    );
}
