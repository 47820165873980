import React, { useState } from 'react';
import "./CompanyRegistration.css"
import cadastro_salao from './images/cadastro_salao.jpg';
import upload from './images/upload.png';
import { SalonService } from '../../services/SalonService';
import { IMaskInput } from "react-imask";
import { useNavigate } from "react-router-dom";
import ArrowLeft from '../../components/arrow-left/ArrowLeft';

export default function CompanyRegistration() {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        companyId: '',
        companyName: '',
        email:'',
        senha:'',
        cnpj: '',
        companyPhone: '',
        companyAdress: '',
        companyImageUrl: ''
    });

    const [companyImage, setCompanyImage] = useState();

    const handleCadastroSalon = async (e) => {
        try {
            e.preventDefault();
            const id = await SalonService.cadastrarSalon(formData);

            SalonService.salvarImagem(id, companyImage);
            navigate(`/login`); // Redireciona para Company Area
        } catch (error) {
            console.error("Erro na resposta da requisição:", error);
            alert("Erro", "Falha ao cadastrar salão. Por favor, tente novamente mais tarde.");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const uploadedImage = (e) => {
        let file = e.target.files[0];
        setCompanyImage(file);

        setFormData({
            ...formData,
            ['companyImageUrl']: formData.companyId
        });
    };

    return (
        <>
        <ArrowLeft />
            <div className='cadastro_salao'>
                <div className='formulario'>
                    <form  className='form' onSubmit={handleCadastroSalon} method='post'>
                        <input className='input' type='hidden' name='companyId' value={formData.companyId}></input>

                        <h2 className='h2'>Cadastre sua empresa</h2>

                        <label className='label'>Nome da empresa </label>
                        <input className='input' type='text' name='companyName' value={formData.companyName} onChange={handleChange} required /><br /><br />

                        <label className='label'>E-mail </label>
                        <input className='input' type='text' name='email' value={formData.email} onChange={handleChange} required /><br /><br />
                       
                        <label className='label'>Senha </label>
                        <input className='input' type='password' name='senha' value={formData.senha} onChange={handleChange} required /><br /><br />

                        <label className='label'>Cnpj </label>
                        <IMaskInput  className='input' mask="00.000.000/0001-00" type='text' name='cnpj' value={formData.cnpj} onChange={handleChange} required /><br /><br />

                        <label className='label'>Endereço </label>
                        <input  className='input' type='text' name='companyAdress' value={formData.companyAdress} onChange={handleChange} required /><br /><br />

                        <label className='label'>Telefone </label>
                        <IMaskInput className='input' mask="(00) 00000-0000" type='text' name='companyPhone' value={formData.companyPhone} onChange={handleChange} required /><br /><br />

                        <div className="imgApp">
                            <div className="parent">
                                <div className="file-upload">
                                    <img className="img-upload" src={upload} alt="upload" />
                                    <p>Envie a imagem da empresa</p>
                                    <input  className='input'  type='file' name='companyImage' accept='.png' onChange={uploadedImage} required></input>
                                </div>
                            </div>
                        </div>

                        <button className='button' type='submit'  >Cadastrar</button><br /><br />
                    </form>
                </div>
                <div className='img' >
                    <img className='img_cadastro_salao' src={cadastro_salao} alt="Imagem Salão" />
                </div>
            </div>
        </>
    )
}

