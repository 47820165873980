import React from 'react';
import "./companyPlans.css";
import Header from "../../components/header/Header";
import ArrowLeft from "../../components/arrow-left/ArrowLeft";
import HorizontalLine from '../../components/hr-line/HorizontalLine';

export default function companyPlans() {
    return (
        <>
            <Header />
            <HorizontalLine />
            <ArrowLeft />

            <h2>Planos Aceitos</h2>
            <div className='CompanyPlans'>
                <form >
                    <div className='listCompanyPlans'>
                        <div className='divListCompanyPlans'>
                            <p className='dataPlans'>Data:</p>
                            <p>23/10/2024</p>
                        </div>
                        <div className='divButton4'><button className='button4' type='submit'>Cancelar</button></div>
                    </div>
                </form>

            </div >


        </>
    )
}