import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardMedia, Typography, Button, Box, TextField, Container } from '@mui/material';
//import imagem from "../../images/salonpicture.png"
import Header from '../../components/header/Header';
import HorizontalLine from '../../components/hr-line/HorizontalLine';
import Footer from '../../components/footer/Footer'

// Componente do card do salão
const SalonCard = ({ salonId, imageUrl, salonName, salonLocation, salonPhone }) => {
    const handleViewMore = () => {
        window.location.href = `/service/${salonId}`; // Redireciona para a página com o ID do salão
    };

    return (
        <Card
            sx={{
                display: 'flex',
                width: 700,
                margin: '20px auto',
                padding: '4px',
                alignItems: 'center',
                borderRadius: '12px',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                alignItems: 'flex-start'
            }}
        >
            <CardMedia
                component="img"
                sx={{
                    width: 'auto',
                    height: 'auto',
                    maxHeight: 200,
                    maxWidth: 200,
                    borderRadius: '10px',
                    objectFit: 'cover',
                    margin: '8px 8px',
                }}
                image={imageUrl || "/default-image.jpg"}  // imagem padrão se estiver vazia
                alt={salonName}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, paddingLeft: '1px' }}>
                <CardContent sx={{ flex: '1 0 auto', textAlign: 'justify', padding: '0', marginTop: '7px', marginBottom: '0px' }}>
                    <Typography
                        component="div"
                        variant="h6"
                        sx={{
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            fontFamily: 'Roboto, sans-serif',
                            marginBottom: '4px'
                        }}
                    >
                        {salonName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" component="div" sx={{ textAlign: 'justify', fontSize: '0.75rem', marginTop: '0' }}>
                        Telefone: {salonPhone}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" component="div" sx={{ textAlign: 'justify', fontSize: '0.75rem', marginTop: '2px' }}>
                        Localização: {salonLocation}
                    </Typography>
                </CardContent>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingRight: 1,
                        paddingBottom: '4px',
                        marginTop: 'auto',
                        paddingTop: '16px'
                    }}
                >
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#DAA520',
                            color: '#fff',
                            '&:hover': { backgroundColor: '#b8860b' },
                            padding: '4px 20px',
                            fontSize: '0.75rem',
                            textTransform: 'none',
                        }}
                        onClick={handleViewMore} // Lógica para redirecionar para a página do salão
                    >
                        Ver mais
                    </Button>
                </Box>
            </Box>
        </Card>
    );
};

// Componente da página de busca
export default function Search() {
    const [salons, setSalons] = useState([]);
    const [searchTerm, setSearchTerm] = useState(''); // Estado para armazenar o texto da barra de busca

    useEffect(() => {
        fetch('https://pmv-ads-2024-2-e4-proj-infra-t6-beauty-qbcl.onrender.com/api/salon', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => setSalons(data))
            .catch((error) => console.error('Erro ao carregar os salões:', error));
    }, []);

    // Filtra os salões com base no valor da barra de busca
    const filteredSalons = salons.filter(salon =>
        salon.companyName.toLowerCase().includes(searchTerm.toLowerCase()) || // Filtro por nome
        salon.companyAdress.toLowerCase().includes(searchTerm.toLowerCase()) // Filtro por localização
    );

    return (
        <>
            <Header />
            <HorizontalLine />
            <Container>
                <Box sx={{ textAlign: 'center', margin: '20px 0' }}>
                    <TextField
                        label="Pesquisar"
                        variant="outlined"
                        fullWidth
                        sx={{ maxWidth: 700 }}
                        value={searchTerm} // Atribui o valor do estado à barra de busca
                        onChange={(e) => setSearchTerm(e.target.value)} // Atualiza o estado com o valor da barra de busca
                    />
                </Box>

                {/* Renderização dos salões filtrados */}
                {filteredSalons.length > 0 ? (
                    filteredSalons.map((salon) => (
                        <SalonCard
                            key={salon.salonId}
                            salonId={salon.salonId} // Passa o ID como parâmetro no botão
                            imageUrl={salon.companyImageUrl}
                            salonName={salon.companyName}
                            salonPhone={salon.companyPhone}
                            salonLocation={salon.companyAdress}
                        />
                    ))
                ) : (
                    <Typography variant="body1" sx={{ textAlign: 'center', marginTop: '20px' }}>
                        Nenhum salão encontrado.
                    </Typography>
                )}
            </Container>
            <Footer />
        </>
    );
}
