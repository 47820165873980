import React from "react";
import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminArea from "./pages/admin-area/AdminArea";
import CompanyArea from "./pages/company-area/CompanyArea";
import CompanyRegistration from "./pages/company-registration/CompanyRegistration";
import CompanyManagement from './pages/company-management/companyManagement';
import ServiceRegistration from './pages/service-registration/serviceRegistration';
import ServiceManagement from './pages/service-management/serviceManagement';
import HomePage from './pages/home-page/HomePage';
import Login from './pages/login/Login';
import Payment from './pages/payment/Payment';
import Ratings from './pages/ratings/Ratings';
import Schedule from './pages/schedule/Schedule';
import CompanyPlans from './pages/company-plans/companyPlans';
import Search from "./pages/search/Search";
import ServiceDetails from "./pages/service-details/ServiceDetails";
import SubscriptionPlans from "./pages/subscription-plans/SubscriptionPlans";
import Support from "./pages/support/Support";
import UserArea from "./pages/user-area/UserArea";
import UserRegistration from "./pages/user-registration/ UserRegistration";
import CreatePlan from "./pages/admin-area/create-plan/CreatePlan";
import EditPlan from "./pages/admin-area/edit-plan/EditPlan";
import Message from "./pages/payment/message/Message";

function App() {
  return (
    <div className="App">
       <BrowserRouter>
          <Routes>
            <Route index element={<HomePage/>} />  
            <Route path="/home-page" element={<HomePage/>} />
            <Route path="/login" element={<Login/>} />
            <Route path="/payment" element={<Payment/>} />
            <Route path="/ratings" element={<Ratings/>} />
            <Route path="/schedule/:salonId/:serviceId" element={<Schedule />} />
            <Route path="/search" element={<Search/>} />
            <Route path="/service/:salonId" element={<ServiceDetails/>} />
            <Route path="/plans" element={<SubscriptionPlans/>} />
            <Route path="/support" element={<Support/>} />
            <Route path="/user-area" element={<UserArea/>} />
            <Route path="/user-registration" element={<UserRegistration/>} />    
            <Route path="/adm-area" element={<AdminArea/>} />    
            <Route path="/company-area/:salonId" element={<CompanyArea/>} />   
            <Route path="/company-management/:salonId" element={<CompanyManagement/>} /> 
            <Route path="/company-plans" element={<CompanyPlans/>} /> 
            <Route path="/service-registration/:salonId" element={<ServiceRegistration/>} /> 
            <Route path="/service-management/:salonId" element={<ServiceManagement/>} /> 
            <Route path="/company-registration" element={<CompanyRegistration/>} />    
            <Route path="/company-area" element={<CompanyArea/>} />    
            <Route path="/company-registration" element={<CompanyRegistration/>} /> 
            <Route path="/create-plan" element={<CreatePlan/>} />  
            <Route path="/edit-plan/:planId" element={<EditPlan />} />   
            <Route path="/payment-message" element={<Message />} />   
          </Routes>
       </BrowserRouter>
    </div>
  );
}

export default App;