import React, { useState, useEffect } from "react";
import Header from "../../components/header/Header";
import ArrowLeft from "../../components/arrow-left/ArrowLeft";
import salonImg from "./assets/salonImg.png";
import HorizontalLine from "../../components/hr-line/HorizontalLine";

import "./ServiceDetails.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";

import { useNavigate, useParams } from "react-router-dom";

export default function ServiceDetails() {
  const [salonDetails, setSalonDetails] = useState(null); // Estado para armazenar os dados do salão
  const [services, setServices] = useState([]); // Estado para armazenar os serviços
  const navigate = useNavigate();
  const { salonId } = useParams(); // Obtém o Id dos parâmetros

  const handleScheduling = (serviceId) => {
    navigate(`/schedule/${salonId}/${serviceId}`); // Envia os Ids para a página de agendamento
  };

  // Função para buscar os dados do salão
  const fetchSalon = async () => {
    try {
      const response = await fetch(
        `https://pmv-ads-2024-2-e4-proj-infra-t6-beauty-qbcl.onrender.com/api/salon/${salonId}`
      );
      if (!response.ok) {
        throw new Error("Erro ao buscar detalhes do salão");
      }
      const data = await response.json();
      setSalonDetails(data.salon); // Atualiza o estado com os detalhes do salão
    } catch (error) {
      console.error(error);
    }
  };

  // Função para buscar os serviços do salão
  const fetchServices = async () => {
    try {
      const response = await fetch(
        `https://servicedetails-vsd2.onrender.com/api/salons/${salonId}/services`
      );
      if (!response.ok) {
        throw new Error("Erro ao buscar serviços");
      }
      const data = await response.json();
      setServices(data.services); // Atualiza o estado com os serviços recebidos
    } catch (error) {
      console.error(error);
    }
  };

  // Chama as funções ao montar os componentes
  useEffect(() => {
    fetchSalon();
    fetchServices();
  }, [salonId]);

  return (
    <>
      <Header />
      <HorizontalLine />
      <ArrowLeft />
      <div className="main-container1">
        <section className="salon-details">
          {salonDetails ? (
            <>
              <h1 className="salon-name">{salonDetails.companyName}</h1>
              <img
                src={salonDetails.companyImageUrl}
                alt="Salão"
                className="salon-img"
              />
              <p className="salon-info">
                <FontAwesomeIcon
                  className="icon"
                  icon={faLocationDot}
                  style={{ color: "#212529" }}
                />
                Endereço: {salonDetails.companyAdress}
              </p>
              <p className="salon-info">
                <FontAwesomeIcon
                  className="icon"
                  icon={faPhone}
                  style={{ color: "#212529" }}
                />
                Contato: {salonDetails.companyPhone}
              </p>
            </>
          ) : (
            <p>Carregando detalhes do salão...</p>
          )}
        </section>

        <hr className="line1" />

        <section className="service-list">
          <h1>Lista de serviços</h1>
          {services.length > 0 ? (
            services.map((service) => (
              <ul className="service-item" key={service.id}>
                <li>
                  <h2>{service.name}</h2>
                  <p>Descrição: {service.description}</p>
                  <p>Duração: {service.duration} minutos</p> {/* Em minutos */}
                </li>
                <button
                  className="schedule-btn"
                  onClick={() => handleScheduling(service.id)}
                >
                  Agendar horário
                </button>
              </ul>
            ))
          ) : (
            <p>Nenhum serviço encontrado para este salão.</p>
          )}
          <hr className="line1" />
        </section>
      </div>
    </>
  );
}
